<div class="z-[10000] fixed top-0 right-5">
  <div [class]="class">
    <p>{{ message }}</p>
  </div>
  <div
    class="absolute right-5 top-8 rounded-xl p-5 z-[1000]"
    [ngClass]="{
      'success-toast': toaster?.type === 'success',
      'error-toast': toaster?.type === 'error',
    }"
    *ngIf="Show"
  >
    <div class="flex gap-4">
      <div class="flex h-fit w-fit">
        <span
          class="text-white rounded-md py-[9.5px] px-[8.5px] flex"
          [ngClass]="{
            'success-icon': toaster?.type === 'success',
            'error-icon': toaster?.type === 'error',
          }"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
            >
              <path
                d="M1.5 4.5L3.5 6.5L8.5 1.5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </span>
      </div>

      <div class="flex flex-col text-left min-w-[200px] lg:min-w-[419px]">
        <span
          class="title"
          [ngClass]="{
            'success-title': toaster?.type === 'success',
            'error-title': toaster?.type === 'error',
          }"
          *ngIf="toaster?.title"
          >{{ toaster?.title }}</span
        >
        <span class="msg flex">{{ toaster?.msg }}</span>
      </div>
      <div>
        <button class="cursor-pointer text-[#979FA9]" (click)="onClose()">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 4.00004L4 14M3.99996 4L13.9999 14"
              stroke="#979FA9"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
