import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '../../services/toast-service/toast.service';
import { CommonModule } from '@angular/common';

type MessageType = 'success' | 'error';
interface StatusType {
  msg: string;
  title: string;
  type: MessageType;
}
@Component({
  selector: 'app-custom-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-toast.component.html',
  styleUrl: './custom-toast.component.css',
})
export class CustomToastComponent implements OnInit, OnDestroy {
  @Input() message: string = '';
  @Input() class: string = '';
  Show = false;
  toaster: StatusType | null | undefined;
  private toastSub: Subscription = new Subscription();

  constructor(private toast: ToastService) {}
  ngOnInit(): void {
    this.toastSub = this.toast.toast$.subscribe((value: StatusType | null) => {
      if (value === null) {
        this.Show = false;
      } else {
        this.Show = true;
        this.toaster = value;
      }
    });
  }
  onClose() {
    this.Show = false;
  }

  ngOnDestroy(): void {
    this.toastSub.unsubscribe();
  }
}
